
import { defineComponent, ref, reactive, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { translate } from "@/hooks/TranslateService";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t, te } = useI18n();
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    document.title = translate("SIGNIN_PAGE_TITLE")

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().required(translate("EMAIL_OR_USERNAME_REQUIRED")).label("Email"),
      password: Yup.string()
        .min(6, translate("PASSWORD_LENGTH_VALIDATION"))
        .required(translate("PASSWORD_REQUIRED"))
        .label("Password"),
    });

    const isAdmin = reactive(computed(() => store.getters.isAdmin));

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          store.dispatch(Actions.VERIFY_AUTH);
          store.dispatch(Actions.REQ_COMPANY);
          store.dispatch(Actions.REQ_COMPANY_LIST_YEAR).then(() => {
            if (isAdmin.value) {
              router.push({ name: "AdminUserList" });
            } else {
              router.push({ name: "dashboard" });
            }
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch(() => {
          Swal.fire({
            text: translate("FEEDBACK_USERNAME_OR_PASSWORD_WRONG"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: translate("TRY_AGAIN_SUBMIT_BUTTON"),
            customClass: {
              confirmButton: "btn fw-bold btn-dark-blue",
            },
          });
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      translate,
    };
  },
});
